export default  {
	red: "#da0000",
	darkred:"#991302",
	green:"#06bd03",
	blue: "#0078D4",
	lightgrey:'#d3d3d3',
	lightergrey:"#f6f6f6",
	lightishgrey:"#e5e5e5",
	darkgrey:'darkgrey',
	dark:"#1a1a1a",
	black:'#0e0e0d',
	white:'#ffffff',
	darkBlue:"#0038e6",
	bhcPurple:"#781f7f",
	bhcPurpleTypo:"#8831B7",
	darkBlue1:'#006492',

	darkGreen:'#029019',

	Tlightgrey:"rgba(211,211,211, 0.7)"
}